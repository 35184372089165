// src/pages/Dashboard.tsx
import React from 'react';
import { Box, Typography } from '@mui/material';

const Dashboard: React.FC = () => {
  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Admin Dashboard
      </Typography>
      <Typography variant="body1">
        Welcome to the admin dashboard. Here you can manage the content of the website.
      </Typography>
    </Box>
  );
};

export default Dashboard;
