// src/App.tsx
import React, { useEffect, useState } from "react";
import AdminRoutes from "./router/Routes";
import { useAuth } from "./auth/useAuth";
import { Box, CircularProgress } from "@mui/material";

const App: React.FC = () => {
  const { loadAuth } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeAuth = async () => {
      await loadAuth(); // Ensure loadAuth is completed
      setLoading(false); // Set loading to false after loadAuth is done
    };
    initializeAuth();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          minHeight: "100vh",
          alignItems: "center",
          backgroundColor: "background.default",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return <AdminRoutes />;
};

export default App;
