import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";

interface RoleUpdateDialogProps {
  open: boolean;
  roles: string[];
  currentRole: string;
  onClose: () => void;
  onSave: (newRole: string) => void;
}

const RoleUpdateDialog: React.FC<RoleUpdateDialogProps> = ({
  open,
  roles,
  currentRole,
  onClose,
  onSave,
}) => {
  const [selectedRole, setSelectedRole] = useState<string>(currentRole);

  const handleRoleChange = (event: SelectChangeEvent<string>) => {
    setSelectedRole(event.target.value);
  };

  const handleSave = () => {
    onSave(selectedRole);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Update User Role</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel>Role</InputLabel>
          <Select value={selectedRole} onChange={handleRoleChange}>
            {roles.map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RoleUpdateDialog;
