import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

export interface JwtPayload {
  uuid: string;
  username: string | null;
  email: string;
  role: string;
  exp: number;
}

export const isTokenExpired = (token: string): boolean => {
  const decoded = jwtDecode<JwtPayload>(token);
  const currentTime = Date.now() / 1000; // Convert to seconds
  return decoded.exp < currentTime;
};

export const useAuth = () => {
  const loadAuth = async () => {
    const token = localStorage.getItem('jwtToken');

    if (token) {
      if (!isTokenExpired(token)) {
        // const user = jwtDecode<JwtPayload>(token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      } else {
        // JWT is expired
        localStorage.removeItem('jwtToken');
        delete axios.defaults.headers.common['Authorization'];
        document.cookie =
          'session_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      }
    } else {
      document.cookie =
        'session_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }
  };
  return { loadAuth };
};
