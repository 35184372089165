import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Input,
  Select,
  MenuItem,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import axios from "axios";
import Config from "../../config";

Quill.register("modules/imageResize", ImageResize);

const EditArticle: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [content, setContent] = useState("");
  const [description, setDescription] = useState("");
  const [banner, setBanner] = useState<File | null>(null);
  const [bannerPreview, setBannerPreview] = useState<string | null>(null);
  const [language, setLanguage] = useState("en");
  const [isTutorial, setIsTutorial] = useState(false);
  const [title, setTitle] = useState("");

  useEffect(() => {
    const fetchArticle = async () => {
      if (id && id != 'new') {
        try {
          const response = await axios.get(
            `${Config.apiDomain}/api/landing/blog/articles/${id}`,
            { params: { language }, withCredentials: true }
          );
          const article = response.data;
          setTitle(article.title || "");
          setDescription(article.description || "");
          setContent(article.content || "");
          setIsTutorial(article.is_tutorial || false);
          const bannerResponse = await axios.get(
            `${Config.apiDomain}/api/landing/${id}/banner`,
            { responseType: 'blob' } // Ensure binary data for image
          );
          const bannerBlob = URL.createObjectURL(bannerResponse.data);
          setBannerPreview(bannerBlob); // Update banner preview with image URL
        } catch (error: any) {
          console.error("Error fetching article:", error);
        }
      }
    };

    fetchArticle();
  }, [id, language]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    let validationErrors: { [key: string]: string } = {};
    if (!title) validationErrors.title = "Title is required";
    if (!description) validationErrors.description = "Description is required";
    if (!content) validationErrors.content = "Content is required";
    if (!bannerPreview) validationErrors.banner = "Banner is required";

    if (Object.keys(validationErrors).length > 0) {
      alert(
        Object.entries(validationErrors)
          .map(([field, message]) => `${field}: ${message}`)
          .join("\n")
      );
      return;
    }
    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    formData.append("description", description);
    if (banner) formData.append("image", banner);
    formData.append("is_tutorial", isTutorial.toString());
    formData.append("language", language);

    try {
      if (id && id != 'new') {
        // If the article already exists, send a PUT request
        formData.append("uuid", id);
        await axios.put(
          `${Config.apiDomain}/api/admin/blog/articles`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
            withCredentials: true,
          }
        );
      } else {
      await axios.post(
        `${Config.apiDomain}/api/admin/blog/articles`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        }
      );}
      navigate("/admin/landing/articles");
    } catch (error: any) {
      alert(error.response?.data?.detail || "Error saving article");
    }
  };

  const handleBannerUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setBanner(selectedFile);

      // Generate a preview URL for the banner image
      const objectUrl = URL.createObjectURL(selectedFile);
      setBannerPreview(objectUrl);
    }
  };

  const quillModules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize"],
    },
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
      <Typography variant="h4" mb={3}>
        Edit Article
      </Typography>
      {id && id != 'new' && (
      <Box mt={2}>
        <Typography variant="subtitle1" mb={1}>
          Select Language
        </Typography>
        <Select
          fullWidth
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
        >
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="zh-cn">Chinese (Simplified)</MenuItem>
          <MenuItem value="zh-tw">Chinese (Traditional)</MenuItem>
          {/* Add more language options as needed */}
        </Select>
      </Box>)}

      <TextField
        label="Title"
        fullWidth
        margin="normal"
        required
        value={title}
        onChange={(e) => setTitle(e.target.value)} // Update title state
      />

      <TextField
        label="Description"
        fullWidth
        margin="normal"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        multiline
        rows={3}
      />

      {/* Banner Upload */}
      <Box mt={2}>
        <Typography variant="subtitle1" mb={1}>
          Upload Banner
        </Typography>
        <Input type="file" onChange={handleBannerUpload} />
        {bannerPreview && (
          <Box
            mt={2}
            sx={{ width: "100%", maxWidth: "600px", overflow: "hidden" }}
          >
            <img
              src={bannerPreview}
              alt="Banner Preview"
              style={{ width: "100%", height: "auto", aspectRatio: "1.77", objectFit: "cover" }}
            />
          </Box>
        )}
      </Box>

      {/* Tutorial Checkbox */}
      <Box mt={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isTutorial}
              onChange={(e) => setIsTutorial(e.target.checked)}
            />
          }
          label="Is this a tutorial?"
        />
      </Box>

      {/* React Quill for content editing */}
      <Box mt={2}>
        <Typography variant="subtitle1" mb={1}>
          Content
        </Typography>
        <ReactQuill
          value={content}
          onChange={setContent}
          modules={quillModules}
          formats={[
            "header",
            "font",
            "size",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "list",
            "bullet",
            "indent",
            "link",
            "image",
            "video",
          ]}
          theme="snow"
        />
      </Box>

      <Button variant="contained" type="submit" sx={{ mt: 3 }}>
        Save
      </Button>
    </Box>
  );
};

export default EditArticle;
