import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  TableSortLabel,
} from "@mui/material";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import Config from "../../config";

interface Article {
  uuid: string;
  title: string;
  created_at: string;
  is_tutorial: boolean;
  updated_at: string;
}

const Articles: React.FC = () => {
  const [data, setData] = useState<Article[]>([]);
  const [sortBy, setSortBy] = useState<"created_at" | "updated_at">(
    "created_at"
  );
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");

  const handleDelete = async (uuid: string) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this article?"
    );
    if (confirmDelete) {
      try {
        await axios.delete(`${Config.apiDomain}/api/admin/blog/article`, {
          data: { uuid },
          withCredentials: true,
        });
        alert("Article deleted successfully");
        // Re-fetch articles to update the list
        const updatedArticles = data.filter((article) => article.uuid !== uuid);
        setData(updatedArticles);
      } catch (error: any) {
        alert(error.response?.data?.detail || "Error deleting article");
      }
    }
  };

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(
          `${Config.apiDomain}/api/admin/blog/articles`,
          {
            params: {
              page: 0,
              limit: 50,
            },
            withCredentials: true,
          }
        );
        setData(response.data.articles);
      } catch (error: any) {
        alert(error.response?.data?.detail || "Error fetching articles");
      }
    };

    fetchArticles();
  }, []);

  // Sorting function
  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a[sortBy]).getTime();
    const dateB = new Date(b[sortBy]).getTime();

    return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
  });

  const handleSortRequest = (property: "created_at" | "updated_at") => {
    if (sortBy === property) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(property);
      setSortOrder("asc");
    }
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Manage Articles
      </Typography>
      <Button
        variant="contained"
        component={Link}
        to="/admin/articles/edit/new"
      >
        Create New Article
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>
              <TableSortLabel
                active={sortBy === "created_at"}
                direction={sortBy === "created_at" ? sortOrder : "asc"}
                onClick={() => handleSortRequest("created_at")}
              >
                Created Date
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sortBy === "updated_at"}
                direction={sortBy === "updated_at" ? sortOrder : "asc"}
                onClick={() => handleSortRequest("updated_at")}
              >
                Updated Date
              </TableSortLabel>
            </TableCell>
            <TableCell>Is Tutorial</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((article) => (
            <TableRow key={article.uuid}>
              <TableCell>{article.title}</TableCell>
              <TableCell>
                {new Date(article.created_at).toLocaleDateString()}
              </TableCell>
              <TableCell>
                {new Date(article.updated_at).toLocaleDateString()}
              </TableCell>
              <TableCell>{article.is_tutorial ? "Yes" : "No"}</TableCell>
              <TableCell>
                <IconButton
                  component={Link}
                  to={`/admin/articles/edit/${article.uuid}`}
                >
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDelete(article.uuid)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default Articles;
