import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import axios from "axios";
import Config from "../../config/index";

Quill.register("modules/imageResize", ImageResize);

interface EditLandingPageProps {
  pageType: "terms" | "privacy" | "about";
}

const EditLandingPage: React.FC<EditLandingPageProps> = ({ pageType }) => {
  const [content, setContent] = useState("");
  const [language, setLanguage] = useState("en");

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await axios.get(
          `${Config.apiDomain}/api/admin/landing/content`,
          {
            params: { type: pageType.toUpperCase(), language },
            withCredentials: true,
          }
        );
        setContent(response.data?.data?.content || "");
      } catch (error: any) {
        alert(error.response?.data?.detail || "Error fetching content");
      }
    };

    fetchContent();
  }, [pageType, language]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      await axios.post(
        `${Config.apiDomain}/api/admin/landing/content`,
        {
          type: pageType.toUpperCase(),
          language: language,
          content_data: {
            content,
          },
        },
        { withCredentials: true }
      );
      alert('Saved')
    } catch (error: any) {
      alert(error.response?.data?.detail || "Error saving content");
    }
  };

  const quillModules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize"],
    },
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
      <Typography variant="h4" mb={3}>
        Edit{" "}
        {pageType === "terms"
          ? "Terms"
          : pageType === "privacy"
          ? "Privacy Policy"
          : "About Us"}
      </Typography>

      {/* Language Selection */}
      <FormControl fullWidth margin="normal">
        <InputLabel>Language</InputLabel>
        <Select
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
          label="Language"
        >
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="zh-tw">Chinese(Tranditional)</MenuItem>
          <MenuItem value="zh-cn">Spanish(Simplified)</MenuItem>
          {/* Add more languages as needed */}
        </Select>
      </FormControl>

      {/* React Quill for content editing */}
      <Box mt={2}>
        <Typography variant="subtitle1" mb={1}>
          Content
        </Typography>
        <ReactQuill
          value={content}
          onChange={setContent}
          modules={quillModules}
          formats={[
            "header",
            "font",
            "size",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "list",
            "bullet",
            "indent",
            "link",
            "image",
            "video",
          ]}
          theme="snow"
        />
      </Box>

      <Button variant="contained" type="submit" sx={{ mt: 3 }}>
        Save
      </Button>
    </Box>
  );
};

export default EditLandingPage;
