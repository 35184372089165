// useUsers.ts
import { useEffect, useState } from "react";
import axios from "axios";
import Config from "../../../config";

export interface User {
  uuid: string;
  role: string;
  username: string;
  email: string;
  school?: string;
  profession?: string;
  discipline?: string;
  how_heard_us?: string;
  is_oauth: boolean;
  auth_type: string;
  finished_signup: boolean;
  last_login?: Date;
}

export interface GetUsersResponse {
  users: User[];
  page: number;
  limit: number;
  total: number;
}

export interface GetAllRolesResponse {
  roles: string[];
}

const useUsers = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [roles, setRoles] = useState<string[]>([]);
  const [total, setTotal] = useState<number>(0);

  const updateUserRole = async (uuid: string, newRoleName: string) => {
    try {
      await axios.put(
        `${Config.apiDomain}/api/admin/users/update_role`,
        {
          uuid,
          new_role_name: newRoleName,
        }
      );
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.uuid === uuid ? { ...user, role: newRoleName } : user
        )
      );
    } catch (error: any) {
      alert(error.response?.data?.detail || "Error updating role");
      console.error("Error updating user role:", error);
      throw error;
    }
  };

  const fetchUsers = async (page: number, limit: number) => {
    setLoading(true);
    try {
      const response = await axios.get<GetUsersResponse>(
        `${Config.apiDomain}/api/admin/users`,
        {
          params: { page, limit },
        }
      );
      setUsers(response.data.users);
      setTotal(response.data.total);
    } catch (error: any) {
      alert(error.response?.data?.detail || "Error fetching users");
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await axios.get<GetAllRolesResponse>(
        `${Config.apiDomain}/api/admin/roles`
      );
      setRoles(response.data.roles);
    } catch (error: any) {
      console.error("Error fetching roles:", error);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  return { users, roles, loading, total, fetchUsers, updateUserRole };
};

export default useUsers;
