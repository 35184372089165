import React, { useState } from "react";
import { Box, TextField, Button, Typography, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Config from "../../config/index";

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      // Call the API to authenticate the user
      const response = await axios.post(`${Config.apiDomain}/api/admin/login`, {
        email,
        password,
      });

      if (response.status === 200) {
        const token = response.data.token;
        // Save token to local storage
        localStorage.setItem("jwtToken", token);
        // Set token to axios headers for future requests
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        navigate("/admin/dashboard");
      }
    } catch (err: any) {
      // Show error if login fails
      alert(err.response?.data?.detail || "Invalid login credentials");
    }
  };

  return (
    <Container maxWidth="sm">
      <Box mt={8}>
        <Typography variant="h4" gutterBottom>
          Login
        </Typography>
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            label="Email"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            label="Password"
            type="password"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Login
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
