//TODO more env

interface Config {
  apiDomain: string;
  domain: string;
  // Add more configuration properties here if needed
}

/* eslint-disable-next-line @typescript-eslint/no-require-imports */
let config: Config = {
  apiDomain: process.env.REACT_APP_API_DOMAIN || "http://localhost:8000",
  domain: process.env.REACT_APP_DOMAIN || "localhost",
};

export default config;
