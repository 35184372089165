// UserDetailsDialog.tsx
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

interface UserDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  user: {
    school?: string;
    profession?: string;
    discipline?: string;
    how_heard_us?: string;
    finished_signup: boolean;
    last_login?: string;
  } | null;
}

const UserDetailsDialog: React.FC<UserDetailsDialogProps> = ({ open, onClose, user }) => {
  if (!user) return null;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>User Details</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1">
          <strong>School:</strong> {user.school || "N/A"}
        </Typography>
        <Typography variant="body1">
          <strong>Profession:</strong> {user.profession || "N/A"}
        </Typography>
        <Typography variant="body1">
          <strong>Discipline:</strong> {user.discipline || "N/A"}
        </Typography>
        <Typography variant="body1">
          <strong>How Heard Us:</strong> {user.how_heard_us || "N/A"}
        </Typography>
        <Typography variant="body1">
          <strong>Finished Sign-Up:</strong> {user.finished_signup ? "Yes" : "No"}
        </Typography>
        <Typography variant="body1">
          <strong>Last Login:</strong> {user.last_login || "N/A"}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserDetailsDialog;
